<template>
  <section class="section home">
    <div class="container">

      <Wizard/>

    </div>
  </section>
</template>

<script>
import Wizard from '../components/Wizard.vue'

export default {
  components: { Wizard }
}
</script>
